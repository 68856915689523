import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import {FaCheckDouble} from 'react-icons/fa'

const About = (props) => {
    const { t } = props;
    useEffect(() => {
        Aos.init({duration:2000});
      }, []);




    return (
        <div id="about">
            <h2 data-aos="zoom-out" className="jHr">{t('About.title')}</h2>
            <hr />
            <div className="container">
                <div className="AboutContainer">
                    <p><span>Universal Study Guide</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda porro optio animi, ipsum aspernatur nam exercitationem expedita deserunt, ipsa, tempora asperiores nostrum! Excepturi quidem corrupti nihil te.</p>
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="AboutList">
                                <li><span><FaCheckDouble /></span><i>Lorem ipsum dolor sit, ame accusantium odio saepe ut adipisci earum! Nesciunt laborios</i> </li>
                                <li><span><FaCheckDouble /></span><i>Lorem ipsum dolor sit, ame accusantium odio saepe ut adipisci earum! Nesciunt laborios</i> </li>
                                <li><span><FaCheckDouble /></span><i>Lorem ipsum dolor sit, ame accusantium odio saepe ut adipisci earum! Nesciunt laborios</i> </li>
                            </ul>
                        </div>
                        <div className="col-md-6 ">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem provident vel tempore recusandae molestiae iure ipsa, enim modi quaerat hic iste amet repudiandae, quis omnis soluta consectetur sequi Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum alias quibusdam, assumenda temporibus quod culpa nihil quaerat fuga, inventore ratione laboriosam dolorem, ipsa eaque. Porro ducimus totam assumenda itaque repellendus. </p>
                            <p><span className="Alink">learn more</span></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About
