import React from 'react';

const NotFound = () => {
  return (
    <div>
      
    </div>
  );
};

export default NotFound;
