import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import {FaUserGraduate,FaUserFriends} from 'react-icons/fa'
import {RiFileTextFill,RiMessage2Fill,RiBook2Fill} from 'react-icons/ri'

const Stats = (props) => {
    const { t } = props;
    useEffect(() => {
        Aos.init({duration:2000});
      }, []);






    return (
        <section   className="overflow" id="statistics">
            <h2 data-aos="zoom-out" className="jHr">{t('Statistics.title')}</h2>

                <p className="text-center Sp">{t('Statistics.text')}</p>


            <div className="jStats">
                <div className="row">
                    <div className="col-md-6">
                        <div className="Sbox text-center">
                            <span><FaUserGraduate /></span>
                            <p className="hashTag">+100</p>
                            <p>{t('Statistics.teachers')}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="SboxL text-center">
                            <span><RiFileTextFill /></span>
                            <p className="hashTag">+8000</p>
                            <p>{t('Statistics.documents')}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="Sbox text-center">
                            <span><FaUserFriends /></span>
                            <p className="hashTag">+100</p>
                            <p>{t('Statistics.connected')}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="SboxL text-center">
                            <span><RiBook2Fill /></span>
                            <p className="hashTag">+100</p>
                            <p>{t('Statistics.chanel')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Stats
