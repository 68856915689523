import React, { useState,useRef } from 'react'
import Logo from '../../images/USD_LOGO-.png'
import {GiHamburgerMenu} from 'react-icons/gi'
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import {getLanguage, setLanguage} from "react-switch-lang";

const Navbar = (props) => {
    const { t } = props;

    const frenchRef = useRef(null);
    const englishRef = useRef(null);
    const [lang, setLang] = useState(getLanguage());


    const handleToogle = ()=>{
        if(frenchRef.current.classList.contains("langActive")){
            frenchRef.current.classList.toggle("langActive");
            englishRef.current.classList.toggle("langActive");
            setLang("en");
            setLanguage("en");
        } else{
            frenchRef.current.classList.toggle("langActive");
            englishRef.current.classList.toggle("langActive");
            setLang("fr");
            setLanguage("fr");
        }
    }


    return (
        <nav className="navbar fixed-top navbar-expand-lg text-center navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href=""><img className="logo" src={Logo} alt="" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggles-icon"><GiHamburgerMenu /></span>
                </button>

                <div className="collapse jNavText navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto jLink mb-2 mb-lg-0">
                        <Link activeClass="active"
                            to="landing"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={0}
                            duration={500}
                            className="nav-item"
                        >
                        <a  className="nav-link">{t('Home.1')}</a>
                        </Link>

                        <Link activeClass="active"
                            to="teacher"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-100}
                            duration={500}
                            className="nav-item"
                        >
                        <a  className="nav-link">{t('Home.2')}</a>
                        </Link>
                        <Link activeClass="active"
                            to="statistics"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-50}
                            duration={500}
                            className="nav-item"
                        >
                        <a  className="nav-link">{t('Home.3')}</a>
                        </Link>
                        <Link activeClass="active"
                            to="about"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-80}
                            duration={500}
                            className="nav-item"
                        >
                        <a  className="nav-link">{t('Home.4')}</a>
                        </Link>
                        <Link activeClass="active"
                            to="team"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-30}
                            duration={500}
                            className="nav-item"
                        >
                        <a  className="nav-link">{t('Home.5')}</a>
                        </Link>
                        <Link activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-50}
                            duration={500}
                            className="nav-item"
                        >
                        <a  className="nav-link">{t('Home.6')}</a>
                        </Link>

                    </ul>
                    <div onClick={() => {} } className="d-flex">
                        <span ref={frenchRef} onClick={handleToogle} className={lang == "fr" ? "langActive langTxt" : "langTxt"}>Français </span>
                        <div onClick={handleToogle} class="switch">
                            <motion.div
                                className="round"
                                animate={{
                                    x: lang == "fr" ? -6 : 15
                                }}
                                initial={{

                                }}
                                transition={{
                                    type:"spring",
                                    stiffness:400
                                }}
                            >
                            </motion.div>
                            <div className="line"></div>
                        </div>
                        <span ref={englishRef} onClick={handleToogle} className={lang == "en" ? "langActive langTxt" : "langTxt"}>English</span>
                    </div>
                </div>

            </div>
        </nav>
    )
}

export default Navbar
