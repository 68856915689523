import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import {motion} from 'framer-motion'
import ImgLanding from '../../images//bg2.jpg'
import {AiFillAndroid,AiOutlineDesktop} from 'react-icons/ai'
import {ImQuotesLeft,ImQuotesRight} from 'react-icons/im'


const index = (props) => {
    const {t}=props;        
    return (
        <section className="overflow" id="landing">
            <div className="landing text-center">
                <div className="landingSection">
                    <div className="landingTxt">
                        <h2><motion.div
                                className="d-inline-block"
                                animate={{
                                    opacity:1,
                                    y:0
                                }}
                                initial={{
                                    opacity:0,
                                    y:200 
                                }}
                                transition={{duration:0.5}}
                            >
                                {t('Landing.txtAnimate1')} 
                            </motion.div> 
                            <motion.div
                                className="d-inline-block p-2"
                                animate={{
                                    opacity:1,
                                    y:0
                                }}
                                initial={{
                                    opacity:0,
                                    y:200 
                                }}
                                transition={{duration:0.7}}   
                            >
                                {t('Landing.txtAnimate2')}
                            </motion.div> 
                            <motion.div
                                className="d-inline-block p-2"
                                animate={{
                                    opacity:1,
                                    y:0 
                                }}
                                initial={{
                                    opacity:0,
                                    y:200 
                                }}
                                transition={{
                                    duration:0.9
                                }}
                            >
                                {t('Landing.txtAnimate3')}
                            </motion.div>  
                        </h2>
                        <p>{t('Landing.1')}</p>
                        <button className="btn jWelcomBtn">hello</button>
                    </div>
                                       
                </div>            
            </div>
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="Lcontainer">
                            <motion.div 
                                className="Lhead"
                                whileHover={{
                                    scale:1.1,
                                    rotate:360
                                
                                }}
                                transition={{
                                    type:"spring",
                                    stiffness:150
                                }}
                                whileTap={{
                                    scale:0.9
                                }}
                                >
                                    <AiFillAndroid />
                            </motion.div>
                            <motion.div 
                                className="jbox"
                                whileHover={{
                                    opacity:1,
                                    y:0
                                }}
                                initial={{
                                    opacity:0.8,
                                    y:15
                                }}
                            >
                                <p><ImQuotesLeft className="quote"/> {t('Landing.phone')}<ImQuotesRight className="quote"/></p>
                            </motion.div>
                            
                        </div>
                    </div>
                    <div className="col text-center">
                        <div className="Lcontainer">
                            <motion.div 
                                
                                className="Lhead"
                                whileHover={{
                                    scale:1.1,
                                    rotate:360
                                
                                }}
                                transition={{
                                    type:"spring",
                                    stiffness:150
                                }}
                                whileTap={{
                                    scale:0.9
                                }}
                            >
                                <AiOutlineDesktop  />
                            </motion.div>
                            <motion.div 
                                className="jbox"
                                whileHover={{
                                    opacity:1,
                                    y:0
                                }}
                                initial={{
                                    opacity:0.8,
                                    y:15
                                }}
                                >
                                <p><ImQuotesLeft className="quote"/> {t('Landing.web')} <ImQuotesRight className="quote"/></p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        
    )
}

export default index;
