import React from 'react';
import QrCode from "../images/qrCode.png";

const UstudyGuideWeb = () => {
  return (
    <section className="webBg vh-100 d-flex align-items-center">
      <div className="container ">
        <div className="webContainer">
          <div className="row JWctn">
            <h3 className="text-center">Welcome to <b>Universal Study Guide</b> <i>Web</i> follow the different
              instructions to get connected</h3>
            <div className="col-md-6 jWebMargin">
              <p><span className="webNum"># 1 </span>- <span>Open the application UstudyGuide</span></p>
              <p><span className="webNum"># 2 </span>- <span>Open the application UstudyGuide</span></p>
              <p><span className="webNum"># 3 </span>- <span>Open the application UstudyGuide</span></p>
            </div>
            <div className="col-md-6 jWebMargin">
              <img src={QrCode}/>
              <div className="mt-3">
                <input type="checkbox" id="keepConnected"/>
                <label className="m-2" htmlFor="keepConnected"> Keep me connected</label><br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UstudyGuideWeb;
