import React from 'react';
import './App.css';
import Home from "./components/pages/Home";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import PropTypes from 'prop-types';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  translate,
} from 'react-switch-lang';
import en from './language/en.json';
import fr from './language/fr.json';
import NotFound from "./components/pages/NotFound";
import WebVersion from "./components/pages/WebVersion";

setTranslations({ en, fr });
setDefaultLanguage('fr');

setLanguageCookie('user_language');

const App = (props) => {
  const { t } = props;
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} t={t}/>} />
        <Route path="/web" exact render={(props) => <WebVersion {...props} t={t}/>} />
        <Route render={(props) => <NotFound {...props} t={t}/>} />} />
      </Switch>
    </BrowserRouter>
  );
}

App.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(App);
