import React,{ useState } from 'react';
import Navbar from '../navbar';
import Landing from '../landing'
import Teacher from '../section/Teacher';
import Stats from '../section/Stats';
import About from '../section/About';
import Team from '../section/Team';
import Footer from '../footer'
import Contact from '../section/Contact';
import { Fragment } from 'react';

const Home = (props) => {
  const { t } = props;

  return (
    <div className="App">
      <Navbar {...props} t={t}/>
      <Fragment>
        <Landing {...props} t={t}/>
        <Teacher {...props} t={t}/>
        <Stats {...props} t={t}/>
        <About {...props} t={t}/>
        <hr />
        <Team {...props} t={t}/>
        <Contact {...props} t={t}/>
      </Fragment>
      <Footer {...props} t={t}/>
    </div>
  );
}

export default Home;
