import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import Profil1 from '../../images/profil/avatar.png'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTwitterCircle,AiFillInstagram} from 'react-icons/ai'

const Team = (props) => {
    const { t } = props;
    useEffect(() => {
        Aos.init({duration:2000});
      }, []);


    return (
        <div className="overflow" id="team">
            <div>
                <h2 data-aos="zoom-out" className="jHr text-center">Team</h2>
                <div className="teamContainer container">
                    <p> UNe team efficace sit. Eveniet, doloremque! Quis, nesciunt odio iste, dolorum itaque sed sit in nostrum, ipsa beatae ipsam blandi</p>
                    <div className="row">
                        <div data-aos="zoom-out" className="col-md-6">
                            <div className="teamBox">
                                <div className="teamBoxFlex">
                                    <div><img className="teamImg" src={Profil1} alt="" /></div>
                                    <div  className="teamName">
                                        <span className="Tname">Dangote Junior  </span><br />

                                        <span className="post">Chief</span>
                                        <hr />
                                        <p><i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea rem dignissimos  </i> </p>
                                        <div className="social">
                                            <span><AiFillInstagram /></span>
                                            <span><FaFacebook /></span>
                                            <span><AiFillTwitterCircle /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div data-aos="zoom-in" className="col-md-6">
                            <div className="teamBox">
                                <div className="teamBoxFlex">
                                    <div><img className="teamImg" src={Profil1} alt="" /></div>
                                    <div  className="teamName">
                                        <span className="Tname">Dangote Junior  </span><br />

                                        <span className="post">Chief</span>
                                        <hr />
                                        <p><i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea rem dignissimos  </i> </p>
                                        <div className="social">
                                            <span><AiFillInstagram /></span>
                                            <span><FaFacebook /></span>
                                            <span><AiFillTwitterCircle /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div data-aos="zoom-out" className="col-md-6">
                            <div className="teamBox">
                                <div className="teamBoxFlex">
                                    <div><img className="teamImg" src={Profil1} alt="" /></div>
                                    <div  className="teamName">
                                        <span className="Tname">Dangote Junior  </span><br />

                                        <span className="post">Chief</span>
                                        <hr />
                                        <p><i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea rem dignissimos  </i> </p>
                                        <div className="social">
                                            <span><AiFillInstagram /></span>
                                            <span><FaFacebook /></span>
                                            <span><AiFillTwitterCircle /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div data-aos="zoom-in" className="col-md-6">
                            <div className="teamBox">
                                <div className="teamBoxFlex">
                                    <div><img className="teamImg" src={Profil1} alt="" /></div>
                                    <div  className="teamName">
                                        <span className="Tname">Dangote Junior  </span><br />

                                        <span className="post">Chief</span>
                                        <hr />
                                        <p><i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea rem dignissimos  </i> </p>
                                        <div className="social">
                                            <span><AiFillInstagram /></span>
                                            <span><FaFacebook /></span>
                                            <span><AiFillTwitterCircle /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div data-aos="zoom-out" className="col-md-6">
                            <div className="teamBox">
                                <div className="teamBoxFlex">
                                    <div><img className="teamImg" src={Profil1} alt="" /></div>
                                    <div  className="teamName">
                                        <span className="Tname">Dangote Junior  </span><br />

                                        <span className="post">Chief</span>
                                        <hr />
                                        <p><i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea rem dignissimos  </i> </p>
                                        <div className="social">
                                            <span><AiFillInstagram /></span>
                                            <span><FaFacebook /></span>
                                            <span><AiFillTwitterCircle /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div data-aos="zoom-in" className="col-md-6">
                            <div className="teamBox">
                                <div className="teamBoxFlex">
                                    <div><img className="teamImg" src={Profil1} alt="" /></div>
                                    <div  className="teamName">
                                        <span className="Tname">Dangote Junior  </span><br />

                                        <span className="post">Chief</span>
                                        <hr />
                                        <p><i>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ea rem dignissimos  </i> </p>
                                        <div className="social">
                                            <span><AiFillInstagram /></span>
                                            <span><FaFacebook /></span>
                                            <span><AiFillTwitterCircle /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Team
