import React from 'react';
import UstudyGuideWeb from "../UstudyGuideWeb";

const WebVersion = () => {
  return (
   <UstudyGuideWeb/>
  );
};

export default WebVersion;
