import React, {useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import {ImLocation} from 'react-icons/im'
import {FaPhoneAlt,FaEnvelope} from 'react-icons/fa'

const Contact = (props) => {
  const { t } = props;
    useEffect(() => {
        Aos.init({duration:2000});
      }, []);

    return (
        <section className="overflow mb-3" id="contact">
            <hr />
            <h2 data-aos="zoom-in" className="jHr text-center">Contact Us</h2>
            <div className="contactContainer container">
                <div className="row">
                    <div className="col-md-6 ">
                        <div className="jIcoContact">
                            <p className=""><span className="Tname"><div className="Tname1"><ImLocation /></div> Location</span><br /><b>yaoundé, Odza, BP : 234432 </b> </p>
                            <p className=""><span className="Tname"><div className="Tname1"><FaEnvelope /></div> Email</span><br /><b>yaoundé, Odza, BP : 234432 </b> </p>
                            <p className=""><span className="Tname"><div className="Tname1"><FaPhoneAlt /></div> Call</span><br /><b>yaoundé, Odza, BP : 234432 </b> </p>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <form>
                            <div className="jflexInput">
                               <input type="text" className="form-control  jM"  name="" id="" placeholder="Name" />
                               <input type="text" className="form-control "  name="" id="" placeholder="Surname" />
                            </div>
                            <input type="text" className="form-control mt-3" placeholder="Subject" />
                            <textarea class="form-control mt-3" id="exampleFormControlTextarea1" rows="3"></textarea>
                            <button className="btn jBtn1 jBtn2">send message</button>

                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
