import React from 'react'
import Logo from '../../images/USD_LOGO-.png'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTwitterCircle,AiFillInstagram} from 'react-icons/ai'

const index = (props) => {
  const { t } = props;
    return (
        <footer className="bgColor">
            <div className="footerMargin"></div>
            <div className="container  text-center">
                <div className="footerContainer">
                    <div><img src={Logo} className="footerLogo" /></div>
                    <p>{t('Landing.1')}</p>
                    <div className="social">
                        <span><AiFillInstagram /></span>
                        <span><FaFacebook /></span>
                        <span><AiFillTwitterCircle /></span>
                    </div>
                </div>
            </div>
            <div className="footerLast text-center">
                <p>&copy; Copyrights <strong>uStudyGuide</strong>. All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default index
