import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import Prof from '../../images/teacher.jpeg';

const Teacher = (props) => {
    const { t } = props;
    useEffect(() => {
        Aos.init({duration:2000});
      }, []);

    return (
        <section className="overflow mt-5" id="teacher">
            <h2 data-aos="zoom-in" className="jHr">{t('Teacher.title')}</h2>
            <div className="section1 p-5">
                <div className="d-flex align-items-center">
                    <div className="col-3 me-3">
                        <img src={Prof} className="img-fluid" alt="" />
                    </div>
                    <div className="col-9">
                        <div className="section1Container">
                            <p className="jFontSize"><div>{t('Teacher.question')}</div> <span>?</span> <b> {t('Teacher.answerBold')}</b>  {t('Teacher.answer')} </p>
                            <p></p>
                        </div>
                        <div className="jboxSection1 mt-5">
                            <h6><span className="hashTag"># </span>{t('Teacher.advantage.title')}</h6>
                            <p className="jFontSizeSm">{t('Teacher.advantage.text')}</p>
                            <button className="btn jBtn1">{t('Teacher.advantage.btn')}</button>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default Teacher
